import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import layout from "../../../styles/Layout.module.css";
import styled from "./Error.module.css";
import * as Sentry from "@sentry/nextjs";
import { isNullorEmpty } from "../../common/utils/CommonUtils";

const NotFound = () => {
  const router = useRouter();
  const [redirectUrl, setRedirectUrl] = useState("");

  useEffect(() => {
    const path = window.location.href;
    let isRedirect = false;

    switch (path) {
      //홈페이지 메인
      case (process.env.NEXT_PUBLIC_HOMEPAGE_DOMAIN ?? "").concat("/hp/main.do"): {
        isRedirect = true;
        setRedirectUrl("/main/");
        break;
      }

      //로그인
      case (process.env.NEXT_PUBLIC_HOMEPAGE_DOMAIN ?? "").concat("/hp/login.do"): {
        isRedirect = true;
        setRedirectUrl("/auth/login/");
        break;
      }

      //아이디, 비밀번호 찾기
      case (process.env.NEXT_PUBLIC_HOMEPAGE_DOMAIN ?? "").concat("/hp/findPwd.do"): {
        isRedirect = true;
        setRedirectUrl("/auth/find/?findType=ID");
        break;
      }

      //비밀번호 변경하기
      case (process.env.NEXT_PUBLIC_HOMEPAGE_DOMAIN ?? "").concat("/hp/myInfoChangePswd.do"): {
        isRedirect = true;
        setRedirectUrl("/mypage/?page=04");
        break;
      }

      //캐시 충전하기
      case (process.env.NEXT_PUBLIC_HOMEPAGE_DOMAIN ?? "").concat("/hp/payModule/CashChargeBnlVoucher.do"): {
        isRedirect = true;
        setRedirectUrl("/cashcharge/?chrgMethSn=0101");
        break;
      }

      //캐시 잠금해지
      case (process.env.NEXT_PUBLIC_HOMEPAGE_DOMAIN ?? "").concat("/hp/cashLockInfo.do"): {
        isRedirect = true;
        setRedirectUrl("/mypage/?page=02");
        break;
      }

      //온라인 사용처안내
      case (process.env.NEXT_PUBLIC_HOMEPAGE_DOMAIN ?? "").concat("/hp/onlineUseConList.do"): {
        isRedirect = true;
        setRedirectUrl("/use/?svcType=2");
        break;
      }

      //캐시 이용내역
      case (process.env.NEXT_PUBLIC_HOMEPAGE_DOMAIN ?? "").concat("/hp/payModule/usedCashList.do"): {
        isRedirect = true;
        setRedirectUrl("/mypage/?page=01");
        break;
      }

      //회사소개
      case (process.env.NEXT_PUBLIC_HOMEPAGE_DOMAIN ?? "").concat("/hp/contxtComInfo.do"): {
        isRedirect = true;
        setRedirectUrl("/company/booknlife/");
        break;
      }

      //개인정보처리방침
      case (process.env.NEXT_PUBLIC_HOMEPAGE_DOMAIN ?? "").concat("/hp/contxtPrsInfo.do"): {
        isRedirect = true;
        setRedirectUrl("/company/booknlife/?page=0301");
        break;
      }

      //상품권 사용확인
      case (process.env.NEXT_PUBLIC_HOMEPAGE_DOMAIN ?? "").concat("/hp/giftUseConfirm.do"): {
        isRedirect = true;
        setRedirectUrl("/customerCenter/giftcard/");
        break;
      }

      //홈페이지 이용안내
      case (process.env.NEXT_PUBLIC_HOMEPAGE_DOMAIN ?? "").concat("/hp/homepageInfo.do"): {
        isRedirect = true;
        setRedirectUrl("/useguide/?maincode=0001");
        break;
      }


      default: {
        break;
      }
    }

    //MMS 잔액조회(indexOf 방식)
    if (path.indexOf("/S/") > 0) {
      const shortenCode = path.replace((process.env.NEXT_PUBLIC_HOMEPAGE_DOMAIN ?? "").concat("/S/"), "").replace("/", "");
      isRedirect = true;
      setRedirectUrl("/remain?shortenCode=" + shortenCode);
    }
    //아이디, 비밀번호 찾기 (?afpopup=0 파라메터 붙은 케이스 처리용)
    else if (path.indexOf("/hp/findId.do") > 0) {
      isRedirect = true;
      setRedirectUrl("/auth/find/?findType=ID");
    }
    //회원가입 (?afpopup=0 파라메터 붙은 케이스 처리용)
    else if (path.indexOf("/hp/memberRegStep01.do") > 0) {
      isRedirect = true;
      setRedirectUrl("/auth/signup/welcome/");
    }
    //온라인 쇼핑 (?WORK_DIV=9999 파라메터 사용)
    else if (path.indexOf("/hp/hpMall.do") > 0) {
      isRedirect = true;
      setRedirectUrl("/use/");
    }

    if (isRedirect === false) {
      Sentry.captureMessage(`Page 404 Error. path = ${router.asPath}`);
    }
  }, []);

  if (isNullorEmpty(redirectUrl) == false) {
    router.replace(redirectUrl);
  }

  return (
    <div className={`${layout.container} ${styled.etc_container}`}>
      <section className={styled.error_404}>
        <h3>
          불편을 드려 죄송합니다.
          <br />
          요청하신 페이지를 찾을 수 없습니다.
        </h3>
        <p>
          자동으로 북앤라이프 홈페이지로 이동이 안되시는 경우
          <br />
          하단 버튼을 클릭해주세요.
        </p>

        <button className={styled.home} onClick={() => router.push("/main")}>
          북앤라이프 홈페이지
        </button>
        <br />
        {isNullorEmpty(redirectUrl) == false && <button onClick={() => router.push(redirectUrl)}>메뉴 바로가기</button>}
      </section>
    </div>
  );
};

export default NotFound;
