import type { NextPageWithLayout } from './_app'
import { ReactElement } from 'react';
import FooterView from '../components/common/footer/FooterView';
import layout from "../../styles/Layout.module.css";
import EtcHeaderView from '../components/error/EtcHeaderView';
import NotFound from '../components/error/NotFound';

const NotFoundPage: NextPageWithLayout = () => {
  return (
    <NotFound/>
  );
};


NotFoundPage.getLayout = function getLayout(page: ReactElement) {

  return (
    <div className={layout.wrapper}>
      <EtcHeaderView />

      {page}

      <FooterView />
    </div>
  );
};

export default NotFoundPage;
