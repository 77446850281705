import Image from "next/image";
import Link from "next/link";
import styled from "./Error.module.css";

const EtcHeaderView = () => {
  return (
    <div className={`${styled.header} ${styled.etc_header}`}>
      <h1>
        <Link href="/main">
          <a>
            <Image src={"/assets/images/logo-logo-booknlife.svg"} width={189} height={48} alt={"logo"} layout="fixed" />
          </a>
        </Link>
      </h1>
    </div>
  );
};

export default EtcHeaderView;
